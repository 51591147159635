/**
 * Capitalizes the first letter of all the words in a given string
 * @param str String of which the first character to be capitalized
 * @param delimeter Delimeter to be used to split the word. Uses space by default
 * @returns
 */
export default function capitalizeFirstLetter(str = '', delimeter = ' ') {
  return str
    ?.toLowerCase()
    .split(delimeter)
    .map(function (word) {
      return word[0].toUpperCase() + word.substr(1);
    })
    .join(delimeter);
}
